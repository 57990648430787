

























import './styles/UserProjectRow.scss';
import './styles/UserProjectRowAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IAllUsersUser } from '@store/modules/user-projects/Interfaces'

@Component({
   name: 'UserProjectRow',
})
export default class UserProjectRow extends Vue {
    @Prop({ default: false }) isOnTop!: boolean;
    @Prop({}) userFullName!: IAllUsersUser;
    @Prop({}) projectTitle!: IAllUsersUser;
    @Prop({}) userRoles!: IAllUsersUser;
}
